.me,
.text {
  display: flex;
}

.me {
  align-items: center;
  gap: 1rem;
}

.text {
  flex-direction: column;
  gap: 0.2rem;
}

.pfp {
  margin: 0 0.25rem;
}
