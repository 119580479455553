.main {
  --padding-x: 5rem;
  --padding-y: 2rem;

  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  min-height: 100vh;
  padding: var(--padding-y) 0;
}

.column {
  display: flex;
  flex-direction: column;
  width: var(--column-width);
  max-width: var(--column-width);
}

.column.me {
  display: none;
}

.column.content {
  gap: 2rem;
}

.column.canvas {
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  cursor: grab;

  height: 100%;
  max-height: calc(100vh - 2 * var(--padding-y));
}

.column.canvas,
.canvasWrapper {
  width: 30vw;
  max-width: var(--column-width);
}

.canvasWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30vw;
  max-height: var(--column-width);
}

.element {
  padding: 0.1rem 0;
}

.element.flex > a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.5rem;
}

.element.logo > a {
  text-decoration: none;
}

.element.logo > a > .text {
  text-decoration: underline;
}

.section.attributions {
  font-size: 0.8rem;
}

.section > h2 {
  padding-bottom: 1rem;
}

@media (max-width: 950px) {
  .main {
    flex-direction: column;
    gap: 1rem;
  }

  .column.me {
    display: flex;
  }

  .column.content {
    order: 1;
  }

  .column.canvas,
  .canvasWrapper {
    width: 100%;
    aspect-ratio: 3 / 2 !important;

    /* fix threejs making canvas bigger than the screen */
    max-width: calc(100vw - 2 * var(--padding-x));
  }

  .canvasWrapper {
    height: auto;
  }

  .column.content > .me {
    display: none;
  }
}

@media (max-width: 700px) {
  .main {
    --padding-x: 2rem;
    --padding-y: 2rem;
    padding: var(--padding-y) var(--padding-x);
  }

  .column {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .canvasWrapper {
    aspect-ratio: 1 / 1 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .canvasWrapper {
    background: radial-gradient(#fff1 0%, #fff0 65%);
  }
}
